import { Avatar, Box, Flex, Heading } from "@chakra-ui/react";

interface NFTItemViewProps {
  name: string,
  image: string
}

const NFTItemView: React.FC<NFTItemViewProps> = (props) => {
  const { name, image } = props;

  return (
    <>
      <Flex
        w="full"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          w="sm"
          mx="auto">

          <Avatar
            variant="roundedSquare"
            src={image}
            boxShadow={"0 6px 0px 0 #373780"}
            borderRadius={'xl'}
            size={{ base: '2xl', xl: '3xl' }}
            css={{
              border: '3px solid rgb(55, 55, 128)',
            }} />

          <Box
            zIndex={2}
            w={{
              base: 40,
              md: 40,
            }}
            bg="white"
            mt={-5}
            css={{
              border: '2px solid rgb(55, 55, 128)',
            }}
            boxShadow={"0 6px 0px 0 #373780"}
            rounded="lg"
            overflow="hidden"
          >
            <Heading
              py={2}
              textAlign="center"
              fontWeight="bold"
              fontSize={'sm'}
              textTransform="uppercase"
              color="rgb(55, 55, 128)"
              letterSpacing={1}
            >
              {name}
            </Heading>
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default NFTItemView;
