import { BigNumber } from "ethers"
import { VaultInfo } from "./VaultInfo"

export interface InfoData {
    fp: number
    rewards: VaultRewards
    vault14: VaultInfo
    vault30: VaultInfo
    vault90: VaultInfo
}

export interface VaultRewards {
    vault14: number
    vault30: number
    vault90: number

}

export const VaultInfoDefault = {
    lockTime: BigNumber.from(0),
    allocation: BigNumber.from(0),
    rewards: BigNumber.from(0),
    claimedRewards: BigNumber.from(0),
    rewardsPerNoodle: BigNumber.from(0),
    stakedNoodlesCount: BigNumber.from(0)
}

export const InfoDataDefault = {
    fp: 0,
    rewards: { vault14: 0, vault30: 0, vault90: 0 },
    vault14: VaultInfoDefault,
    vault30: VaultInfoDefault,
    vault90: VaultInfoDefault
}