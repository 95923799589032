import {
  Box,
  Flex,
  Image,
  Button,
  useColorModeValue,
  Center,
  Wrap,
  WrapItem,
  useColorMode,
  Spacer,
  Heading,
  Tooltip,
  useDisclosure,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Text,
  HStack,
  VStack,
  Collapse,
  IconButton
} from "@chakra-ui/react";

import {
  Link as RouterLink,
} from "react-router-dom";

import { FaMoon, FaSun, FaDiscord, FaTwitter } from "react-icons/fa";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
interface NavItem {
  label: string;
  children?: Array<NavItem>;
  href?: string;
  isExternal?: boolean;
}

interface HeaderNavigationProps {
  isConnected: boolean
  account: string | undefined
  isCorrectChainId: boolean
  toggleConnect: () => void
  switchNetwork: () => void
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = (props) => {
  const NAVIGATION_ITEMS: Array<NavItem> = [
    {
      label: 'Home',
      href: '/',
    },
    {
      label: 'My Collection',
      href: '/collection',
    },
    {
      label: 'Staking',
      href: '/staking',
    },
    {
      label: 'Marketplace',
      href: '/marketplace',
    },
    {
      label: 'Seasons',
      href: '/seasons',
    }
  ]

  const { isOpen, onToggle, onClose } = useDisclosure();

  const account = props.account

  const popoverContentBgColor = "gray.50"

  const items = <>
    {NAVIGATION_ITEMS.map((navItem) => (
      <Box key={navItem.label}>
        {navItem.children
          ?
          <>
            <Menu>
              <MenuButton as={Button} variant={'ghost'} rightIcon={navItem.children ? <ChevronDownIcon /> : null}>
                {navItem.label}
              </MenuButton>
              <MenuList
                bgColor={popoverContentBgColor}
                borderColor={popoverContentBgColor}
              >
                {navItem.children.map((child, index) => (
                  <MenuItem key={index} _focus={{}} onClick={() => {
                    onClose();
                  }}>
                    <SubNav key={child.label} {...child} />
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </>
          :
          <RouterLink to={navItem.href ?? '#'}>
            <Button onClick={() => {
              onClose();
            }} variant={'ghost'}>{navItem.label}</Button>
          </RouterLink>}
      </Box>
    ))}
  </>

  const desktopNavigation = <>
    <Stack direction={'row'} spacing={2}>
      <Wrap>
        {items}
      </Wrap>
    </Stack>
  </>

  const mobileNavigation = <>
    <Stack
      display={{ md: 'none' }}>
      {items}
    </Stack>
  </>

  return (
    <>
      <Box
        minW={360}
        mx={4}
        mb={4}
        bg={"gray.50"}
        sx={{ position: "sticky", top: "4", zIndex: "999" }}
        px={4}
        textColor={'rgb(55, 55, 128)'}
        boxShadow={"0 6px 0px 0 #373780"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
          borderRadius: '36px'
        }}
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <IconButton
            onClick={onToggle}
            display={{ base: 'flex', md: 'none' }}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />

          <Flex display={{ base: 'none', md: 'flex' }} py={4}>
            {desktopNavigation}
          </Flex>
          <Spacer />

          <HStack px={2}>
            <HStack>
              <Tooltip label={account ? `${account.slice(0, 6)}...${account.slice(-4)}` : null}>
                <Button variant={'ghost'} size={'sm'} onClick={props.toggleConnect}>
                  {props.isConnected ? "Disconnect" : "Connect Wallet"}
                </Button>
              </Tooltip>
            </HStack>
          </HStack>
        </Flex>

        <Collapse in={isOpen} animateOpacity>
          {mobileNavigation}
        </Collapse>
      </Box>
    </>
  )
};

const SubNav = ({ label, href, isExternal }: NavItem) => {
  const link = <>
    <Box>
      <Text p={2}
        transition={'all .3s ease'}
        _hover={{ color: 'brand' }}
        fontWeight={400}>
        {label}
      </Text>
    </Box>
  </>

  if (isExternal) {
    return (
      <Link
        style={{ textDecoration: 'none' }}
        isExternal
        href={href ?? "#"}
      >
        {link}
      </Link>
    );
  }
  else {
    return (
      <RouterLink
        to={href ?? "#"}
      >
        {link}
      </RouterLink>
    );
  }
};

export default HeaderNavigation;
