import {
  Box,
  VStack,
  Text,
  Image,
  Divider,
  Flex,
  Spacer,
  Button,
  Wrap,
  WrapItem,
  Avatar,
  Heading,
  HStack,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Checkbox,
  Stack,
  Radio,
  RadioGroup,
  Container,
} from "@chakra-ui/react";
import { merge, tap } from "rxjs";
import { useEffect, useState } from "react";

import StakingImage from "../images/staking.webp";
import Web3Service, { VaultType } from "../core/web3.service";
import { NoodleTokens } from "../core/StakedToken";
import { InfoData, InfoDataDefault } from "../core/InfoData";
import { ethers } from "ethers";
import { TokenInfo } from "../core/TokenInfo";

function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function (item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : num.toFixed(2);

}

const StakingView: React.FC = () => {
  const web3Service = Web3Service.shared();

  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>();
  const [tokens, setTokens] = useState<NoodleTokens>({ all: [], vault14: [], vault30: [], vault90: [] });
  const [isApproved, setIsApproved] = useState<boolean | undefined>(undefined);
  const [selectedTokens, setSelectedTokens] = useState<number[]>([]);
  const [stakeOption, setStakeOption] = useState<VaultType>(VaultType.VAULT14);
  const [infoData, setInfoData] = useState<InfoData>(InfoDataDefault);

  useEffect(() => {
    web3Service.getStakedTokens()
    web3Service.getInfoData()

    const connected$ = web3Service.connected$.pipe(
      tap((connected) => {
        setConnected(connected);
      })
    );

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const tokens$ = web3Service.stakedTokens$.pipe(
      tap((tokens) => {
        setSelectedTokens([])
        setTokens(tokens);
      })
    );

    const approvedForAll$ = web3Service.approvedForAll$.pipe(
      tap((approvedForAll) => {
        setIsApproved(approvedForAll);
      })
    );

    const infoData$ = web3Service.infoData$.pipe(
      tap((infoData) => {
        setInfoData(infoData)
      })
    );

    const subscription = merge(
      connected$,
      account$,
      tokens$,
      approvedForAll$,
      infoData$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, [account]);

  const allStaked = [...tokens.vault14, ...tokens.vault30, ...tokens.vault90].map((id) => Number(id))
  const unstakedTokens = tokens.all.filter(id => !allStaked.some(sId => id == sId))

  let stakeAllButton
  if (isApproved) {
    stakeAllButton = <>
      <Button
        isDisabled={unstakedTokens.length == 0}
        onClick={() => {
          web3Service.stake(stakeOption, unstakedTokens)
        }}
        isLoading={isApproved === undefined}
        bg={'white'}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
      >
        {`Stake All`}
      </Button>
    </>
  }

  const stakeView = <>
    <Wrap px={2} py={4} textColor={'black'} justify='center'>
      {tokens.all.length == 0 ? <Text py={8}>No Noodles available</Text> : undefined}
      {tokens.all.map((token, i) => (
        <WrapItem key={i}>
          {
            <NoodleNFTView
              id={Number(token)}
              selected={selectedTokens.some(e => e === Number(token)) ? true : false}
              onClick={() => {
                var copy = selectedTokens
                if (selectedTokens.some(e => e === token)) {
                  const index = copy.indexOf(token, 0);
                  if (index > -1) {
                    copy.splice(index, 1);
                  }
                } else {
                  copy.push(token)
                }
                setSelectedTokens([...copy])
              }} />
          }
        </WrapItem>
      ))}
    </Wrap>

    <RadioGroup defaultValue={"14"}>
      <Stack spacing={5} direction='row'>
        <Radio colorScheme='brand' value={"14"} onChange={() => {
          setStakeOption(VaultType.VAULT14)
        }}>
          14 days
        </Radio>
        <Radio colorScheme='brand' value={"30"} onChange={() => {
          setStakeOption(VaultType.VAULT30)
        }}>
          30 days
        </Radio>
        <Radio colorScheme='brand' value={"90"} onChange={() => {
          setStakeOption(VaultType.VAULT90)
        }}>
          90 days
        </Radio>
      </Stack>
    </RadioGroup>

    <HStack>
      {stakeAllButton}

      <Button
        isDisabled={selectedTokens.length == 0 && isApproved}
        onClick={() => {
          isApproved ? web3Service.stake(stakeOption, selectedTokens) : web3Service.approveForAll()
        }}
        isLoading={isApproved === undefined}
        bg={'white'}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
      >
        {isApproved ? (selectedTokens.length > 0 ? `Stake ${selectedTokens.length}` : `Stake`) : 'Approve All'}
      </Button>
    </HStack>

    {/* <Text textColor={'black'} fontSize={'12px'}>Up to 30 per transaction</Text> */}
  </>

  const totalDistributed = [infoData.vault14.rewards, infoData.vault30.rewards, infoData.vault90.rewards]
    .map(n => Number(ethers.utils.formatEther(n)))
    .reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )

  const claimRewardsView = <>
    <SimpleGrid
      pt={8}
      pb={4}
      columns={{ base: 2, md: 3, lg: 3 }}
      spacing={{ base: 2, md: 2, lg: 4 }}
      my={{ base: 4, md: 8 }}
      mx={"auto"}
      px={{ base: 0, md: 4 }}
      maxW={"6xl"}
    >
      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>Available to claim</StatLabel>
        <StatNumber>{nFormatter(infoData.rewards.vault14 + infoData.rewards.vault30 + infoData.rewards.vault90, 2)} CRO</StatNumber>
      </Stat>

      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>Total Distributed</StatLabel>
        <StatNumber>{nFormatter(totalDistributed, 2)} CRO</StatNumber>
      </Stat>

      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>Floor Price</StatLabel>
        <StatNumber>{nFormatter(infoData.fp, 2)} CRO</StatNumber>
      </Stat>
    </SimpleGrid>

    <Text mt={8}>Total Noodles Staked</Text>

    <SimpleGrid
      pb={4}
      columns={{ base: 3, md: 3, lg: 3 }}
      spacing={{ base: 2, md: 2, lg: 4 }}
      my={{ base: 4, md: 8 }}
      mx={"auto"}
      px={{ base: 0, md: 4 }}
      maxW={"6xl"}
    >
      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>14 days</StatLabel>
        <StatNumber>{Number(infoData.vault14.stakedNoodlesCount)}</StatNumber>
      </Stat>

      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>30 days</StatLabel>
        <StatNumber>{Number(infoData.vault30.stakedNoodlesCount)}</StatNumber>
      </Stat>

      <Stat bg={'white'}
        rounded={"xl"}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}
        maxWidth={480}
        p={4}>
        <StatLabel>90 days</StatLabel>
        <StatNumber>{Number(infoData.vault90.stakedNoodlesCount)}</StatNumber>
      </Stat>
    </SimpleGrid>

    {/* <Text textColor={'black'} fontSize={'12px'}>Up to 30 per transaction</Text> */}
  </>

  const connectWalletButton = <>
    <Box pt={8}>
      <Button onClick={web3Service.toggleConnect}
        bg={'white'}
        css={{
          border: '2px solid rgb(55, 55, 128)',
        }}
        boxShadow={"0 6px 0px 0 #373780"}>
        {"Connect Wallet"}
      </Button>
    </Box>
  </>

  const vaults = <>
    <Box
      borderTopRadius={4}
      p={4}
      width={'100%'}
      backgroundColor={'white'}
      textColor={'rgb(55, 55, 128)'}
      boxShadow={"0 6px 0px 0 #373780"}
      css={{
        border: '2px solid rgb(55, 55, 128)',
        borderRadius: '36px'
      }}
    >
      <Flex>
        <Text fontWeight={600}>Your Vaults ({allStaked.length} staked)</Text>
      </Flex>
    </Box>
    <Tabs mt={8} isFitted variant='soft-rounded'>
      <TabList>
        <Tab _selected={{ color: 'rgb(55, 55, 128)', bg: 'white', border: '2px solid rgb(55, 55, 128)' }}>14 days ({tokens.vault14.length} staked)</Tab>
        <Tab _selected={{ color: 'rgb(55, 55, 128)', bg: 'white', border: '2px solid rgb(55, 55, 128)' }}>30 days ({tokens.vault30.length} staked)</Tab>
        <Tab _selected={{ color: 'rgb(55, 55, 128)', bg: 'white', border: '2px solid rgb(55, 55, 128)' }}>90 days ({tokens.vault90.length} staked)</Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          {
            <VaultView id={1} duration={14} tokens={tokens.vault14} vaultType={VaultType.VAULT14} rewards={infoData.rewards.vault14} />
          }
        </TabPanel>
        <TabPanel>
          {
            <VaultView id={2} duration={30} tokens={tokens.vault30} vaultType={VaultType.VAULT30} rewards={infoData.rewards.vault30} />
          }
        </TabPanel>
        <TabPanel>
          {
            <VaultView id={2} duration={90} tokens={tokens.vault90} vaultType={VaultType.VAULT90} rewards={infoData.rewards.vault90} />
          }
        </TabPanel>
      </TabPanels>
    </Tabs>
  </>

  return (
    <>
      <Box maxW={"6xl"} px={{ base: 4, md: 8, lg: 16 }}>
        <Box>
          <Flex>
            <Box
              position={'relative'}
              my={8}
              flex={2}
              mr={4}
              borderRadius={8}
            >
              <VStack pb={8}>
                <Box
                  borderTopRadius={4}
                  p={4}
                  width={'100%'}
                  backgroundColor={'white'}
                  textColor={'rgb(55, 55, 128)'}
                  boxShadow={"0 6px 0px 0 #373780"}
                  css={{
                    border: '2px solid rgb(55, 55, 128)',
                    borderRadius: '36px'
                  }}
                >
                  <Flex>
                    <Text fontWeight={600}>Stake your Noodles</Text>
                  </Flex>
                </Box>

                {connected ? stakeView : connectWalletButton}
                {claimRewardsView}

              </VStack>
              <Image src={StakingImage}
                display={{ base: 'none', md: 'flex' }}
                boxShadow={"0 6px 0px 0 #373780"}
                boxSize={80}
                zIndex={-2}
                position={'absolute'}
                rounded={'xl'}
                top={'25%'}
                left={'100%'}
                css={{
                  transform: 'scaleX(-1)'
                }}
              />
            </Box>
            <Spacer display={{ base: 'none', md: 'block' }} />
          </Flex>
          {connected ? vaults : undefined}
        </Box>
      </Box>
      {/* <HowItWorksView/> */}
    </>
  );
};

interface NoodleItem {
  id: number,
  selected: boolean,
  onClick: () => void,
}

const NoodleNFTView: React.FC<NoodleItem> = (props) => {
  const { id, selected, onClick } = props

  return <>
    <Flex maxW={32}
      pt={4}
      alignItems="center"
      justifyContent="center"
      cursor={'pointer'}
      onClick={onClick}
    >
      <Flex
        position={'relative'}
        direction="column"
        justifyContent="center"
        alignItems="center"
        w="sm"
        mx="auto">

        <Avatar
          variant="roundedSquare"
          src={`https://bafybeibnvd6akht62krize4orcwtrwaterhwgybfymdh4punc3dlknuqsu.ipfs.dweb.link/${id}.png`}
          boxShadow={"0 6px 0px 0 #373780"}
          borderRadius={'xl'}
          size={{ base: 'xl', xl: 'xl' }}
          css={{
            border: '3px solid rgb(55, 55, 128)',
          }} />

        <Box
          zIndex={2}
          bg="white"
          px={3}
          mt={-5}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          boxShadow={"0 2px 0px 0 #373780"}
          rounded="lg"
          overflow="hidden"
        >
          <Heading
            py={1}
            textAlign="center"
            fontWeight="bold"
            fontSize={'sm'}
            textTransform="uppercase"
            color="rgb(55, 55, 128)"
            letterSpacing={1}
          >
            #{id}
          </Heading>
        </Box>

        <Checkbox
          isReadOnly
          isChecked={selected}
          position={'absolute'}
          colorScheme={'brand'}
          left={6}
          top={2}
        />
      </Flex>
    </Flex>
  </>
};


interface Vault {
  id: number,
  duration: number,
  tokens: number[]
  vaultType: VaultType
  rewards: number
}

const VaultView: React.FC<Vault> = (props) => {
  const { duration, tokens, vaultType, rewards } = props
  const web3Service = Web3Service.shared();

  const [selectedUnstakeTokens, setSelectedUnstakeTokens] = useState<number[]>([]);

  return <>
    <Container centerContent>
      <HStack spacing={8}>
        <Stat bg={'white'}
          rounded={"xl"}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          boxShadow={"0 6px 0px 0 #373780"}
          maxWidth={480}
          p={4}>
          <StatLabel>Available to claim</StatLabel>
          <StatNumber>{nFormatter(rewards, 2)} CRO</StatNumber>
        </Stat>
        <VStack alignItems={'left'}>
          <Button
            isDisabled={selectedUnstakeTokens.length == 0}
            onClick={() => {
              web3Service.claimRoyalties(vaultType, selectedUnstakeTokens)
            }}
            bg={'white'}
            css={{
              border: '2px solid rgb(55, 55, 128)',
            }}
            boxShadow={"0 6px 0px 0 #373780"}
          >
            {"Claim"}
          </Button>

          <Button
            isDisabled={tokens.length == 0}
            onClick={() => {
              web3Service.claimRoyalties(vaultType, tokens)
            }}
            bg={'white'}
            css={{
              border: '2px solid rgb(55, 55, 128)',
            }}
            boxShadow={"0 6px 0px 0 #373780"}
          >
            {"Claim All"}
          </Button>
        </VStack>
      </HStack>
      <Text hidden={tokens.length > 0} py={8}>No Noodles staked for {duration} days yet</Text>
      <Wrap px={2} py={4} textColor={'black'} justify='center'>
        {tokens.map((token, i) => (
          <WrapItem key={i}>
            {
              <NoodleNFTVaultView
                id={Number(token)}
                selected={selectedUnstakeTokens.some(e => e === Number(token)) ? true : false}
                vaultType={vaultType}
                onClick={(canUnstake) => {
                  var copy = selectedUnstakeTokens
                  if (selectedUnstakeTokens.some(e => e === Number(token))) {
                    const index = copy.indexOf(Number(token), 0);
                    if (index > -1) {
                      copy.splice(index, 1);
                    }
                  } else {
                    copy.push(Number(token))
                  }
                  setSelectedUnstakeTokens([...copy])
                }} />
            }
          </WrapItem>
        ))}
      </Wrap>
      <HStack>
        <Button
          isDisabled={selectedUnstakeTokens.length == 0}
          onClick={() => {
            web3Service.unstake(vaultType, selectedUnstakeTokens)
          }}
          bg={'white'}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          boxShadow={"0 6px 0px 0 #373780"}
        >
          {selectedUnstakeTokens.length > 0 ? `Untake ${selectedUnstakeTokens.length}` : `Unstake`}
        </Button>

        <Button
          onClick={() => {
            web3Service.unstake(vaultType, tokens)
          }}
          bg={'white'}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          boxShadow={"0 6px 0px 0 #373780"}
        >
          {`Unstake All`}
        </Button>
      </HStack>
    </Container>
  </>
}

interface NoodleVaultItem {
  id: number,
  selected: boolean,
  vaultType: VaultType,
  onClick: (canUnstake: boolean) => void,
}

const NoodleNFTVaultView: React.FC<NoodleVaultItem> = (props) => {
  const { id, selected, vaultType, onClick } = props

  const web3Service = Web3Service.shared();
  const [tokenInfo, setTokenInfo] = useState<TokenInfo | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let isSubscribed = true

    const fetchInfo = async () => {
      const info = await web3Service.getTokenInfo(vaultType, id)

      if (isSubscribed) {
        setTokenInfo(info);
        setLoading(false)
      }
    }

    fetchInfo().catch()

    return () => {
      isSubscribed = false
    }
  }, []);

  const expireDate = new Date(Number(tokenInfo?.lockExpiration ?? 0) * 1000)
  const now = new Date()
  const canUnstake = !loading && (expireDate <= now) && (tokenInfo?.staked ?? false)

  return <>
    <Flex maxW={32}
      opacity={loading ? 0.5 : 1.0}
      pt={4}
      alignItems="center"
      justifyContent="center"
      cursor={'pointer'}
      onClick={() => {
        onClick(canUnstake)
      }}
    >
      <Flex
        position={'relative'}
        direction="column"
        justifyContent="center"
        alignItems="center"
        w="sm"
        mx="auto">

        <Avatar
          variant="roundedSquare"
          src={`https://bafybeibnvd6akht62krize4orcwtrwaterhwgybfymdh4punc3dlknuqsu.ipfs.dweb.link/${id}.png`}
          boxShadow={"0 6px 0px 0 #373780"}
          borderRadius={'xl'}
          size={{ base: 'xl', xl: 'xl' }}
          css={{
            border: '3px solid rgb(55, 55, 128)',
          }} />

        <Box
          zIndex={2}
          position={'absolute'}
          bg="white"
          px={1}
          right={5}
          top={1}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          rounded="lg"
          overflow="hidden"
        >
          <Heading
            py={1}
            textAlign="center"
            fontWeight="bold"
            fontSize={'sm'}
            textTransform="uppercase"
            color="rgb(55, 55, 128)"
            letterSpacing={1}
          >
            #{id}
          </Heading>
        </Box>

        <Checkbox
          isReadOnly
          isChecked={selected}
          position={'absolute'}
          colorScheme={'brand'}
          left={6}
          top={2}
        />

        <Box
          zIndex={2}
          bg={'white'}
          rounded={'xl'}
          css={{
            border: '2px solid rgb(55, 55, 128)',
          }}
          mt={-5}
          px={4}
          overflow="hidden"
        >
          <VStack maxW={24} spacing={0}>
            <Text textColor={'brand'} fontSize={'12px'}>{nFormatter(tokenInfo?.rewards ?? 0, 2)} CRO</Text>
            <Text textColor={'brand'} fontSize={'12px'} fontWeight={700}>{loading ? 'Loading' : (canUnstake ? 'Staked' : 'Locked')}</Text>
          </VStack>
        </Box>
      </Flex>
    </Flex>
  </>
};

export default StakingView;