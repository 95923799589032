import {
    Container, Text,
} from "@chakra-ui/react";

const MarketplaceView: React.FC = () => {
    return (
        <>
            <Container centerContent mt={8}>
                <Text>Coming Soon</Text>
            </Container>
        </>
    );
};

export default MarketplaceView;