import { BigNumber, ethers } from "ethers";
import Web3Modal from "web3modal";
import { Subject, BehaviorSubject } from 'rxjs'
import { STAKING_ADDRESS, STAKING_ABI, NOODLES_ADDRESS, NOODLES_ABI, TOKENS_WALLET_ADDRESS, TOKENS_WALLET_ABI } from ".././config"
import { ChainModel } from "./ChainModel";

import WalletConnectProvider from "@walletconnect/web3-provider";
import { NoodleTokens } from "./StakedToken";
import { InfoData, InfoDataDefault, VaultInfoDefault } from "./InfoData";
import { TokenInfo } from "./TokenInfo";

export enum VaultType {
    VAULT14, VAULT30, VAULT90
}

export default class Web3Service {
    static instance: Web3Service

    static shared() {
        if (Web3Service.instance) {
            return Web3Service.instance
        } else {
            Web3Service.instance = new Web3Service()
            return Web3Service.instance
        }
    }

    static cronosRpc = "https://rpc.vvs.finance";
    static defaultProvider = new ethers.providers.JsonRpcProvider(Web3Service.cronosRpc);

    // Private
    private _connected$ = new BehaviorSubject<boolean>(false)
    private _isLoading$ = new BehaviorSubject<boolean>(false)
    private _account$ = new BehaviorSubject<string | undefined>(undefined)
    private _infoData$ = new BehaviorSubject<InfoData>(InfoDataDefault)
    private _tokens$ = new BehaviorSubject<number[]>([])
    private _stakedTokens$ = new BehaviorSubject<NoodleTokens>({ all: [], vault14: [], vault30: [], vault90: [] })
    private _approvedForAll$ = new BehaviorSubject<boolean | undefined>(undefined)
    private _signature$ = new BehaviorSubject<string | undefined>(undefined)
    private _showToast$ = new Subject<{ title: string }>()
    private _errors$ = new Subject<string>()

    // Public
    public readonly connected$ = this._connected$.asObservable()
    public readonly account$ = this._account$.asObservable()
    public readonly infoData$ = this._infoData$.asObservable()
    public readonly tokens$ = this._tokens$.asObservable()
    public readonly stakedTokens$ = this._stakedTokens$.asObservable()
    public readonly approvedForAll$ = this._approvedForAll$.asObservable()
    public readonly signature$ = this._signature$.asObservable()
    public readonly showToast$ = this._showToast$.asObservable()
    public readonly errors$ = this._errors$.asObservable()
    public readonly isLoading$ = this._isLoading$.asObservable()

    // Logic
    private web3Modal: Web3Modal
    private provider?: ethers.providers.Web3Provider
    private didConnectOnLoad: boolean = false
    private connector: any

    private _chain = new ChainModel(
        25,
        "Cronos",
        "Crypto.org Coin",
        18,
        "CRO",
        ['https://evm.cronos.org']
    )

    constructor() {
        const providerOptions = {
            "custom-walletconnect": {
                display: {
                    logo: "https://docs.walletconnect.com/img/walletconnect-logo.svg",
                    name: "WalletConnect",
                    description: "Connect with any WalletConnect compatible wallet."
                },
                options: {
                    appName: 'Moon Crew Apes',
                    networkUrl: 'https://evm-cronos.crypto.org/',
                    chainId: 25
                },
                package: WalletConnectProvider,
                connector: async () => {
                    const connector = new WalletConnectProvider({
                        rpc: {
                            25: "https://evm-cronos.crypto.org"
                        },
                        chainId: 25,
                    });
                    await connector.enable();
                    this.connector = connector;
                    return connector;
                }
            },
        }

        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions
        })
    }

    isCorrectChainId = () => {
        if (window.ethereum) return window.ethereum.networkVersion == this._chain.id
        return true
    }

    connectToCachedProvider = async () => {
        if (this.didConnectOnLoad || !this.web3Modal.cachedProvider) return

        this._isLoading$.next(true)
        this.didConnectOnLoad = true
        this.web3Modal.connectTo(this.web3Modal.cachedProvider)
            .then(provider => {
                this.walletConnected(provider)
                this._isLoading$.next(false)
            })
            .catch(error => {
                this._errors$.next("Failed to connect")
                this._isLoading$.next(false)
            })
    }

    toggleConnect = async () => {
        if (this._connected$.value) {
            try {
                this.connector.disconnect();
            }
            catch (error) {
            }
            try {
                this.connector.deactivate();
            }
            catch (error) {
            }

            this.disconnect()
        } else {
            this.connectToWallet()
        }
    }

    switchNetwork = async () => {
        if (this.isCorrectChainId()) return

        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: ethers.utils.hexValue(this._chain.id) }]
            })
        } catch (err: any) {
            if (err.code == 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [{
                        chainName: this._chain.name,
                        chainId: ethers.utils.hexValue(this._chain.id),
                        nativeCurrency: { name: this._chain.currencyName, decimals: this._chain.decimals, symbol: this._chain.symbol },
                        rpcUrls: this._chain.rpcUrls
                    }]
                })
            } else if (err.code == 4901) {
                return
            } else {
                this._errors$.next('There was a problem adding ' + this._chain.name + ' network to MetaMask')
            }
        }
    }

    private walletConnected(provider: any) {
        this.provider = new ethers.providers.Web3Provider(provider)
        this._connected$.next(true)
        this._showToast$.next({ title: "Wallet connected" })
        this.getAccount()

        this.observeWalletChanges()
    }

    private observeWalletChanges() {
        this.removeListeners()

        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")

        provider.on("network", (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload()
            }
        })

        window.ethereum.on("accountsChanged", (accounts: string[]) => {
            if (accounts[0] && accounts[0] != this._account$.value) {
                this.getAccount()
            }
        })
    }

    private removeListeners() {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
        provider.removeAllListeners()
    }

    private connectToWallet = async () => {
        this.web3Modal.clearCachedProvider()

        this._isLoading$.next(true)
        this.web3Modal.connect()
            .then(provider => {
                this._isLoading$.next(false)
                this.didConnectOnLoad = true
                this.walletConnected(provider)

            })
            .catch(error => {
                this._isLoading$.next(false)
                this._errors$.next("Failed to connect")
            })
    }

    private disconnect = async () => {
        this.web3Modal.clearCachedProvider()
        this.provider = undefined
        this._connected$.next(false)
        this._account$.next(undefined)
    }

    private getAccount = async () => {
        if (!this.provider) return

        this._isLoading$.next(true)
        const signer = this.provider.getSigner();
        const address = (await signer.getAddress()).toLowerCase();
        this._account$.next(address)
        this._isLoading$.next(false)

        this.isApprovedForAll()
    }

    claimRoyalties = async (vault: VaultType, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return

        this._isLoading$.next(true)

        const signer = this.provider.getSigner()
        const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, signer)

        try {

            switch (vault) {
                case VaultType.VAULT14:
                    const tx = await contract.claimRewards14(tokens)
                    await tx.wait()
                    break
                case VaultType.VAULT30:
                    const tx30 = await contract.claimRewards30(tokens)
                    await tx30.wait()
                    break
                case VaultType.VAULT90:
                    const tx90 = await contract.claimRewards90(tokens)
                    await tx90.wait()
                    break
            }

            this._showToast$.next({ title: "Rewards have been claimed" })
            await this.getInfoData()
            await this.getStakedTokens()
        } catch (e) {
            this._errors$.next("Could not claim royalties")
        } finally {
            this._isLoading$.next(false)
        }
    }

    // STAKING
    isApprovedForAll = async () => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(NOODLES_ADDRESS, NOODLES_ABI, this.provider)

        try {
            const approved = await contract.isApprovedForAll(this._account$.value, STAKING_ADDRESS)
            this._approvedForAll$.next(approved)
        } catch (e) {
            //
        }
    }

    approveForAll = async () => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(NOODLES_ADDRESS, NOODLES_ABI, this.provider.getSigner())

        this._isLoading$.next(true)

        try {
            const tx = await contract.setApprovalForAll(STAKING_ADDRESS, true)
            await tx.wait()
            this._showToast$.next({ title: "Staking Approved" })
            await this.isApprovedForAll()
        } catch (e) {
            this._errors$.next("Failed to approve")
        } finally {
            this._isLoading$.next(false)
        }
    }

    getTokens = async () => {
        if (!this._account$.value) return
        const contract = new ethers.Contract(TOKENS_WALLET_ADDRESS, TOKENS_WALLET_ABI, this.provider ?? Web3Service.defaultProvider)

        try {
            const tokens = await contract.tokensOfWallet(this._account$.value, "0x72F1dde65de4968d5b3F7eB7384AEb32d904f954")
            this._tokens$.next(tokens.map((token: BigNumber) => Number(token)))
        } catch {
            this._tokens$.next([])
        } finally {

        }
    }

    getInfoData = async () => {
        if (!this._account$.value) return // TODO: remove
        const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, this.provider ?? Web3Service.defaultProvider)
        this._isLoading$.next(true)

        try {
            const fp = await contract.floorPrice()
            const info14 = await contract.getVault14Info()
            const info30 = await contract.getVault30Info()
            const info90 = await contract.getVault90Info()

            var claim14 = BigNumber.from(0);
            var claim30 = BigNumber.from(0);
            var claim90 = BigNumber.from(0);
            if (this._account$.value) {
                claim14 = await contract.getRewardsVault14(this._account$.value)
                claim30 = await contract.getRewardsVault30(this._account$.value)
                claim90 = await contract.getRewardsVault90(this._account$.value)
            }

            this._infoData$.next({ fp: Number(ethers.utils.formatEther(fp)), rewards: { vault14: Number(ethers.utils.formatEther(claim14)), vault30: Number(ethers.utils.formatEther(claim30)), vault90: Number(ethers.utils.formatEther(claim90)) }, vault14: info14, vault30: info30, vault90: info90 })
        } catch (e) {
            console.log(e)
            this._infoData$.next({ fp: 0, rewards: { vault14: 0, vault30: 0, vault90: 0 }, vault14: VaultInfoDefault, vault30: VaultInfoDefault, vault90: VaultInfoDefault })
        } finally {
            this._isLoading$.next(false)
        }
    }

    getStakedTokens = async () => {
        if (!this.provider || !this._account$.value) return

        const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, this.provider)
        const wallet = this._account$.value

        this._isLoading$.next(true)

        try {
            const tokens = await contract.tokensOfWallet(this._account$.value)
            const mapped = tokens.map((token: BigNumber) => Number(token))

            const stakedTokens: NoodleTokens = await contract.getStakedTokens(wallet)

            this._stakedTokens$.next({ all: mapped, vault14: stakedTokens.vault14, vault30: stakedTokens.vault30, vault90: stakedTokens.vault90 })
        } catch (e) {
            console.log(e)
            this._stakedTokens$.next({ all: [], vault14: [], vault30: [], vault90: [] })
        } finally {
            this._isLoading$.next(false)
        }
    }

    getTokenInfo = async (vault: VaultType, token: number): Promise<TokenInfo | undefined> => {
        if (!this.provider || !this._account$.value) return undefined

        try {
            const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, this.provider)
            var rewards = 0;
            switch (vault) {
                case VaultType.VAULT14:
                    rewards = Number(ethers.utils.formatEther(await contract.getRewardsVault14ForToken(token)))
                    break
                case VaultType.VAULT30:
                    rewards = Number(ethers.utils.formatEther(await contract.getRewardsVault30ForToken(token)))
                    break
                case VaultType.VAULT90:
                    rewards = Number(ethers.utils.formatEther(await contract.getRewardsVault90ForToken(token)))
                    break
            }

            const info = await contract.tokensInfo(token)
            return { staked: info.staked, lockExpiration: Number(info.lockExpiration), rewards: rewards }
        } catch (e) {
            console.error(e)
            return undefined
        }
    }

    stake = async (vault: VaultType, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, this.provider.getSigner())

        this._isLoading$.next(true)
        try {
            switch (vault) {
                case VaultType.VAULT14:
                    const tx14 = await contract.stake14(tokens)
                    await tx14.wait()
                    break
                case VaultType.VAULT30:
                    const tx30 = await contract.stake30(tokens)
                    await tx30.wait()
                    break
                case VaultType.VAULT90:
                    const tx90 = await contract.stake90(tokens)
                    await tx90.wait()
                    break
            }
            this._showToast$.next({ title: `You have staked ${tokens.length} Noodle` })
            await this.getStakedTokens()
        } catch (e) {
            console.log(e)
            this._errors$.next("Failed to stake. Please, try again.")
        } finally {
            this._isLoading$.next(false)
        }
    }

    unstake = async (vault: VaultType, tokens: number[]) => {
        if (!this.provider || !this._account$.value) return
        const contract = new ethers.Contract(STAKING_ADDRESS, STAKING_ABI, this.provider.getSigner())

        this._isLoading$.next(true)
        try {
            // const fp = await contract.floorPrice()
            // const fee = Number(ethers.utils.formatEther(fp)) * tokens.length / 2.0
            const fee = await contract.priceForUnlocking(tokens)
            switch (vault) {
                case VaultType.VAULT14:
                    const tx14 = await contract.unstake14(tokens, { value: fee })
                    await tx14.wait()
                    break
                case VaultType.VAULT30:
                    const tx30 = await contract.unstake30(tokens, { value: fee })
                    await tx30.wait()
                    break
                case VaultType.VAULT90:
                    const tx90 = await contract.unstake90(tokens, { value: fee })
                    await tx90.wait()
                    break
            }
            this._showToast$.next({ title: `You have unstaked ${tokens.length} Noodle` })
            await this.getStakedTokens()
        } catch (e) {
            this._errors$.next("Failed to unstake. Please, try again.")
        } finally {
            this._isLoading$.next(false)
        }
    }
}