import {
  Box,
  ChakraProvider,
  extendTheme,
  Progress, useToast,
} from "@chakra-ui/react";
import { merge, tap } from "rxjs";
import { useEffect, useState } from "react";

import Web3Service from "./core/web3.service";

import HeaderNavigation from "./components/HeaderNavigation";

import { Dict } from "@chakra-ui/utils";
import HomeView from "./components/HomeView";
import StakingView from "./components/StakingView";
import HowItWorksView from "./components/HowItWorksView";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import SeasonsView from "./components/SeasonsView";
import MarketplaceView from "./components/MarketplaceView";
import CollectionView from "./components/CollectionView";
import Fonts from "./BodoFont";

function App(): any {
  const toast = useToast();
  const web3Service = Web3Service.shared();

  const [isLoading, setIsLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [account, setAccount] = useState<string>();

  useEffect(() => {
    web3Service.connectToCachedProvider();

    const connected$ = web3Service.connected$.pipe(
      tap((connected) => {
        setConnected(connected);
      })
    );

    const account$ = web3Service.account$.pipe(
      tap((account) => {
        setAccount(account);
      })
    );

    const showToast$ = web3Service.showToast$.pipe(
      tap((info: { title: string }) => {
        toast({
          description: info.title,
          status: "success",
          position: 'bottom-left'
        });
      })
    );

    const errors$ = web3Service.errors$.pipe(
      tap((error) => {
        toast({
          description: error,
          status: "error",
          position: 'bottom-left'
        });
      })
    );

    const isLoading$ = web3Service.isLoading$.pipe(
      tap((isLoading) => {
        setIsLoading(isLoading);
      })
    );

    const subscription = merge(
      connected$,
      showToast$,
      errors$,
      account$,
      isLoading$
    ).subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const config = {
    initialColorMode: "light",
    useSystemColorMode: false,
  };

  const styles = {
    global: (props: Dict<any>) => ({
      body: {
        bg: "rgb(197, 197, 255)",
      },
    }),
  };


  const theme = extendTheme({
    config,
    fonts: {
      heading: `'Bodo Amat', sans-serif`,
      body: `'Bodo Amat', sans-serif`,
    },
    styles,
    colors: {
      brand: {
        50: 'rgb(55, 55, 128)',
        100: 'rgb(55, 55, 128)',
        200: 'rgb(55, 55, 128)',
        500: 'rgb(55, 55, 128)',
        700: 'rgb(55, 55, 128)',
        900: 'rgb(55, 55, 128)'
      }
    }
  });

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <Progress
        colorScheme={'brand'}
        sx={{ position: "sticky", top: "0", zIndex: "999" }}
        size="xs"
        isIndeterminate
        opacity={isLoading ? 100 : 0}
      />

      <Box
        margin={'0 auto'}
        maxW={1200}
      >

        <Router>
          <HeaderNavigation
            isConnected={connected}
            account={account}
            isCorrectChainId={web3Service.isCorrectChainId()}
            toggleConnect={web3Service.toggleConnect}
            switchNetwork={web3Service.switchNetwork}
          />

          <Routes>
            <Route path='/collection' element={<CollectionView />} />
            <Route path='/staking' element={<StakingView />} />
            <Route path='/seasons' element={<SeasonsView />} />
            <Route path='/marketplace' element={<MarketplaceView />} />
            <Route path='/' element={<HomeView />} />
          </Routes>
        </Router>
        {/* <HomeView />
        <StakingView />
        <HowItWorksView /> */}
      </Box>

    </ChakraProvider >
  );
}

export default App;
