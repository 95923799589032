import {
  Box,
  Center,
  VStack,
  Text,
} from "@chakra-ui/react";

const HomeView: React.FC = () => {
  return (
    <>
      <Box>
        <Box position={'relative'}>
          <Center textAlign={'center'}>
            <VStack px={2} py={32} spacing={0}>
              <VStack
                fontSize={64} fontWeight={800}
                textColor={'rgb(55, 55, 128)'}
                textShadow="#ffffff 3px 3px 0px, rgb(0 0 0 / 20%) 6px 6px 0px"
              >
                <Text>Welcome to  </Text>
                <Text>Noodles Club</Text>
              </VStack>
            </VStack>
          </Center>
        </Box>
      </Box>
    </>
  );
};

export default HomeView;